<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/tanq.png')" alt="" />
    <span class="title">企业评分</span>
    <div class="basic-info-con">
      <div class="bas-l">
        <img :src="require('@/assets/images/btzs.png')" alt="" />
        <span>基础数据展示</span>
      </div>
    </div>
    <div class="detection-con">
      <div class="top-con">
        <div class="top-ri">
          <div class="c-mian-con">
            <img :src="require('@/assets/images/qiyphz.png')" alt="" />
            <!-- <div class="qiyphz-con">
              <div class="item-row">
                <img :src="require('@/assets/images/luma.png')" alt="" />
                <h1>{{ greenCodeList.length }}</h1>
                <h1>绿码企业</h1>
              </div>
              <div class="item-row">
                <img :src="require('@/assets/images/hangm.png')" alt="" />
                <h1>{{ yellowCodeList.length }}</h1>
                <h1>黄码企业</h1>
              </div>
              <div class="item-row">
                <img :src="require('@/assets/images/hongm.png')" alt="" />
                <h1>{{ redCodeList.length }}</h1>
                <h1>红码企业</h1>
              </div>
            </div> -->
            <div class="qiyphz-con">
              <div id="qybar" class="qybar"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-con">
        <div class="c-mian-con">
          <img :src="require('@/assets/images/zhaungsi.png')" alt="" />
          <div class="qiyezs-list">
            <div class="item-row">
              <div class="tc Ninety">
                <span>90分以上</span>
              </div>
              <!-- <img :src="require('@/assets/images/lvmqy.png')" alt="" /> -->
              <div v-if="NinetyList.length > 0" class="news-list">
                <div
                  v-for="(item, idx) in NinetyList"
                  :key="idx"
                  class="info-row"
                >
                  <h1>{{ item.subjectName }}</h1>
                </div>
              </div>
            </div>
            <div class="item-row">
              <div class="tc eighty">
                <span>80分-89分</span>
              </div>
              <!-- <img :src="require('@/assets/images/hmqy.png')" alt="" /> -->
              <div v-if="eightyList.length > 0" class="news-list">
                <div
                  v-for="(item, idx) in eightyList"
                  :key="idx"
                  class="info-row"
                >
                  <h1>{{ item.subjectName }}</h1>
                </div>
              </div>
            </div>
            <div class="item-row">
              <div class="tc seventy">
                <span>70分-79分</span>
              </div>
              <!-- <img :src="require('@/assets/images/homqy.png')" alt="" /> -->
              <div v-if="seventyList.length > 0" class="news-list">
                <div
                  v-for="(item, idx) in seventyList"
                  :key="idx"
                  class="info-row"
                >
                  <h1>{{ item.subjectName }}</h1>
                </div>
              </div>
            </div>
            <div class="item-row">
              <div class="tc sixty">
                <span>60分-69分</span>
              </div>
              <div v-if="sixtyList.length > 0" class="news-list">
                <div
                  v-for="(item, idx) in sixtyList"
                  :key="idx"
                  class="info-row"
                >
                  <h1>{{ item.subjectName }}</h1>
                </div>
              </div>
            </div>
            <div class="item-row">
              <div class="tc fifty">
                <span>60分以下</span>
              </div>
              <div v-if="fiftyList.length > 0" class="news-list">
                <div
                  v-for="(item, idx) in fiftyList"
                  :key="idx"
                  class="info-row"
                >
                  <h1>{{ item.subjectName }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import dict from "@/mixins/index/index.js";
import * as echarts from "echarts";
import moment from "moment";
// import { BigNumber } from "bignumber.js";

export default {
  props: {
    productName: String,
    regionCode: String,
    regionLevel: Number,
    productNameList: Array,
  },
  components: {},
  data() {
    return {
      loading: false,
      ispictureshow: true,
      hgzmrdysl: [],
      recSwiperOptionzs: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperzs: true,
      // 绿码
      recSwiperOptionlm: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperlm: true,
      // 黄码
      recSwiperOptionham: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperham: true,
      //绿码
      recSwiperOptionhm: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperhm: true,
      NinetyList: [], //90
      eightyList: [], //80
      seventyList: [], //70
      sixtyList: [], //60
      fiftyList: [], //50
      hegedabiaoList: [],
      statisticsResult: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.subjectScore();
    // this.getpromiseQualified();
  },
  mixins: [dict],
  methods: {
    closeHand() {
      this.$emit("close");
    },
    //获取
    async subjectScore() {
      let query = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
      };
      try {
        let response = await overlayApi.bigScreenScore(query);
        let { classifyResult, statisticsResult } = response.data;
        for (let index = 0; index < statisticsResult.length; index++) {
          const element = statisticsResult[index];
          if (element.name == "Ninety") {
            element.name = "90分以上";
          }
          if (element.name == "eighty") {
            element.name = "80分-89分";
          }
          if (element.name == "seventy") {
            element.name = "70分-79分";
          }
          if (element.name == "sixty") {
            element.name = "60分-69分";
          }
          if (element.name == "fifty") {
            element.name = "60分以下";
          }
        }
        this.statisticsResult = statisticsResult;
        this.NinetyList = classifyResult.Ninety;
        this.eightyList = classifyResult.eighty;
        this.fiftyList = classifyResult.fifty;
        this.seventyList = classifyResult.seventy;
        this.sixtyyList = classifyResult.sixty;
        await this.$nextTick();
        this.barqyChart();
      } catch (error) {
        console.log(error);
      }
    },
    //承诺达标
    async getpromiseQualified() {
      let query = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
      };
      try {
        let res = await overlayApi.promiseQualified(query);
        this.hegedabiaoList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    barqyChart() {
      var chartDom = document.getElementById("qybar");
      try {
        this.barChartInstance.clear();
      } catch (error) {
        console.log(error);
      }
      this.barChartInstance = echarts.init(chartDom);
      let myChart = this.barChartInstance;
      let names = this.statisticsResult.map((item) => {
        return item.name;
      });
      let counts = this.statisticsResult.map((item) => {
        return item.count;
      });
      let min = Math.min(...counts);
      let xData = [],
        yData = [];
      this.statisticsResult.map(function (a, b) {
        xData.push(a.name);
        if (a.count === 0) {
          yData.push(a.count);
        } else {
          yData.push(a.count);
        }
      });
      let option = {
        backgroundColor: "#111c4e",
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              opacity: 0,
            },
          },
          formatter: function (prams) {
            return prams[0].data + "家";
            // if (prams[0].data === min) {
            //   return "合格率：0%";
            // } else {
            //   return "合格率：" + prams[0].data + "%";
            // }
          },
        },
        // legend: {
        //   data: ["直接访问", "背景"],
        //   show: false,
        // },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "5%",
          top: "7%",
          height: "85%",
          containLabel: true,
          z: 22,
        },
        xAxis: [
          {
            type: "category",
            gridIndex: 0,
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: "#0c3b71",
              },
            },
            axisLabel: {
              show: true,
              color: "#fff",
              fontSize: 16,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            gridIndex: 0,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            // min: min,
            // max: 10000,
            axisLine: {
              lineStyle: {
                color: "#0c3b71",
              },
            },
            axisLabel: {
              color: "#fff",
              formatter: "{value}",
            },
          },
          {
            type: "value",
            gridIndex: 0,
            // min: min,
            // max: 10000,
            splitNumber: 12,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"],
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "30%",
            xAxisIndex: 0,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00feff",
                  },
                  {
                    offset: 0.5,
                    color: "#027eff",
                  },
                  {
                    offset: 1,
                    color: "#0286ff",
                  },
                ]),
              },
            },
            data: counts,
            zlevel: 11,
          },
          {
            name: "背景",
            type: "bar",
            barWidth: "50%",
            xAxisIndex: 0,
            yAxisIndex: 1,
            barGap: "-135%",
            data: counts,
            itemStyle: {
              normal: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            zlevel: 9,
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    // width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 44px;
    left: 108px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .basic-info-con {
    position: absolute;
    top: 118px;
    left: 74px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .bas-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 33px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #07e6ff;
      }
    }
  }
  .detection-con {
    position: absolute;
    left: 50%;
    top: 172px;
    transform: translateX(-50%);
    width: 1439px;
    .cpjcs {
      position: relative;
    }
    .c-mian-con {
      padding: 16px;
      width: 100%;
    }
    .top-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .top-left {
        height: 363px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        .qiyepfzs {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-top: 34px;
          padding: 0 33px;
          width: 100%;
          .cldbqys {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 40px;
            img {
              width: 100px;
              height: 100px;
              margin-bottom: 10px;
            }
            h1 {
              text-align: center;
              &:nth-child(even) {
                font-weight: 400;
                color: #c1dde5;
                font-size: 36px;
                margin-bottom: 13px;
              }
              &:nth-child(odd) {
                font-weight: 400;
                color: #f1fbfe;
                font-size: 20px;
              }
            }
          }
        }
      }
      .top-ri {
        width: 100%;
        height: 363px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        margin-right: 20px;
        .qiyphz-con {
          width: 100%;
          height: 280px;
          .qybar {
            width: 100%;
            height: 100%;
          }
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          // .item-row {
          //   display: flex;
          //   flex-direction: column;
          //   justify-content: center;
          //   align-items: center;
          //   img {
          //     width: 100px;
          //     height: 100px;
          //     margin-bottom: 20px;
          //   }
          //   h1 {
          //     color: #fff;
          //     &:nth-child(even) {
          //       font-weight: 400;
          //       font-size: 40px;
          //     }
          //     &:nth-child(odd) {
          //       font-weight: 400;
          //       font-size: 16px;
          //     }
          //   }
          // }
        }
      }
    }
    .bottom-con {
      width: 1439px;
      height: 364px;
      background-color: rgba($color: #1a3566, $alpha: 0.23);
      border-radius: 20px;
      margin-top: 15px;
      position: relative;
      .qiyezs-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 7px;
        .item-row {
          flex: 1;
          .news-list {
            width: 100%;
            box-sizing: border-box;
            height: 250px;
            overflow-y: auto;
          }
          .info-row {
            padding: 5px 13px;
            margin-top: 10px;
            height: 30px;
            text-align: center;
            h1 {
              font-size: 16px;
              font-weight: 400;
              color: #f7fefe;
              // width: 100%;
              // white-space: nowrap; /* 确保文本不换行 */
              // overflow: hidden; /* 隐藏溢出的内容 */
              // text-overflow: ellipsis; /* 添加省略符号 */
            }
            &:nth-child(odd) {
              background: #142951;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 74px;
    top: 99px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .bas-r {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    .option-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 18px;

      img {
        height: 11px;
        width: 11px;
        margin-right: 5px;
      }
      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .qiyepfzs-r {
    display: flex;
    flex-direction: column;
    flex: 1;
    .qydys-list {
      margin-top: 15px;

      .title-con {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #18efff;
          text-align: left;
        }
      }
    }
    .news-list {
      width: 100%;
      box-sizing: border-box;
      height: 225px;
      overflow-y: auto;
    }

    .info-row {
      padding: 5px 13px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      height: 30px;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #f7fefe;
        text-align: left;
        &:nth-child(1) {
          width: 191px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .level {
        margin-right: 80px;
      }
      &:nth-child(odd) {
        background: #142951;
        border-radius: 5px;
      }
    }
  }
  .news-list::-webkit-scrollbar {
    display: none;
  }
  .tc {
    // border-radius: 5px;
    text-align: center;
    padding: 10px 0;
    flex: 1;
  }
  .Ninety {
    // background: linear-gradient(to right, #00b33e, #5ef579);
    background: linear-gradient(to right, #c2e7b0, #007bff);
    // background-color: rgba($color: #5ef579, $alpha: 0.45);
  }
  .eighty {
    background: linear-gradient(45deg, #007bff, #ff69b4);
  }
  .seventy {
    background: linear-gradient(45deg, #ff69b4, #ffa500);
  }
  .sixty {
    background: linear-gradient(to right, #ffa500, #ff0000);
  }
  .fifty {
    background: linear-gradient(to right, #ff0000, #ffff00);
  }
}
</style>
