<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/tanq.png')" alt="" />
    <span class="title">日常巡查管理</span>
    <div class="basic-info-con">
      <div class="bas-l">
        <img :src="require('@/assets/images/xzsj.png')" alt="" />
      </div>
      <div class="bas-r">
        <!-- <div class="time-con">
          <a-date-picker
            :default-value="beganTime"
            :allowClear="false"
            @change="startChange"
          />
          <h1 class="line"></h1>
          <a-date-picker
            :allowClear="false"
            @change="endChange"
            :default-value="endTime"
          />
          <img
            class="shanchu"
            :src="require('@/assets/images/shanchu.png')"
            alt=""
            @click="shanchuHand"
          />
        </div> -->
        <div
          v-for="item in optionList"
          :key="item.id"
          @click="switchHand(item)"
          class="option-list"
        >
          <img
            v-if="item.check"
            :src="require('@/assets/images/wxz.png')"
            alt=""
          />
          <img v-else :src="require('@/assets/images/xz.png')" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="chart-con">
      <div class="chart-from-left">
        <div class="c-mian-con">
          <img :src="require('@/assets/images/rcxc.png')" alt="" />
          <progress-line v-if="lineShow" :hgzTopTen="hgzTopTen" :type="2" />
        </div>
      </div>
      <div class="chart-from-r">
        <div class="top-con">
          <div class="c-mian-con">
            <img :src="require('@/assets/images/xcgl.png')" alt="" />
            <div class="title-con">
              <span>巡查人员</span>
              <span>企业名称</span>
              <span>巡查地区</span>
              <span>巡查时间</span>
            </div>

            <div v-if="patrolList.length > 0" class="list-con">
              <!-- <swiper
                :options="recSwiperOptionrc"
                :not-next-tick="notNextTickOfRecSwiperrc"
                ref="recSwiperhz"
                class="swiper-item"
              >
                <swiper-slide >
                 
                </swiper-slide>
              </swiper> -->
              <div
                v-for="(item, idx) in patrolList"
                :key="idx"
                class="info-row"
              >
                <!-- <img src="" alt=""> -->
                <span>{{ item.createUserName }}</span>
                <span>{{ item.subjectName }}</span>
                <span>{{ item.regionName }}</span>
                <span v-if="item.createTime">{{
                  item.createTime.substring(0, 10)
                }}</span>
              </div>
            </div>
            <div v-else class="available">
              <a-empty />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import dict from "@/mixins/index/index.js";
import progressLine from "@/components/line-progress/index.vue";
import moment from "moment";
export default {
  props: {
    regionCode: String,
    regionLevel: Number,
  },
  components: {
    progressLine,
  },
  data() {
    return {
      loading: false,
      qiyedata: [],
      beganTime: "",
      endTime: "",
      optionList: [
        {
          check: true,
          name: "本月",
          id: 1,
        },
        {
          check: false,
          name: "本周",
          id: 2,
        },
        {
          check: false,
          name: "昨日",
          id: 3,
        },
      ],
      lineShow: false,
      recSwiperOptionrc: {
        initialSlide: 0,
        slidesPerView: 10,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperrc: true,
      patrolList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mixins: [dict],
  mounted() {
    this.beganTime = moment().startOf("month").format("YYYY-MM-DD");
    this.endTime = moment().endOf("month").format("YYYY-MM-DD");
    this.queryHand();
    this.getpatrolRecordManage();
  },

  methods: {
    //地区巡查次数
    async queryHand() {
      this.loading = true;
      try {
        let query = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
          beganTime: `${this.beganTime} 00:00:00`,
          endTime: `${this.endTime} 23:59:59`,
        };
        let response = await overlayApi.patrolRecordRank(query);
        let list = response.data.slice(0, 8);
        let total = 0;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          total += element.numCount;
        }
        let lineList = list.map((item) => {
          let percent = ((item.numCount / total) * 100).toFixed(2);
          return {
            number: Number(percent),
            name: item.regionName,
            percent: item.numCount,
          };
        });
        this.hgzTopTen = lineList;
        this.lineShow = true;
      } catch (error) {
        console.log(error);
      }
    },
    //巡查管理
    async getpatrolRecordManage() {
      try {
        let query = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
          beganTime: `${this.beganTime} 00:00:00`,
          endTime: `${this.endTime} 23:59:59`,
        };
        let response = await overlayApi.patrolRecordManage(query);
        this.patrolList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    closeHand() {
      this.$emit("close");
    },
    //查询
    switchHand(item) {
      this.optionList.forEach((element) => {
        if (element.check) {
          element.check = false;
        }
      });
      item.check = true;
      this.beganTime = "";
      this.endTime = "";
      if (item.id == 1) {
        //本月
        let start = moment().startOf("month").format("YYYY-MM-DD");
        let end = moment().endOf("month").format("YYYY-MM-DD");
        this.beganTime = start;
        this.endTime = end;
        this.queryHand();
        this.getpatrolRecordManage();
      } else if (item.id == 2) {
        //本周
        let start = moment().startOf("week").format("YYYY-MM-DD");
        let end = moment().endOf("week").format("YYYY-MM-DD");
        this.beganTime = start;
        this.endTime = end;
        this.queryHand();
        this.getpatrolRecordManage();
      } else if (item.id == 3) {
        this.endTime = moment().subtract(1, "day").format("YYYY-MM-DD");
        this.beganTime = moment(this.endTime).format("YYYY-MM-DD");
        this.queryHand();
        this.getpatrolRecordManage();
      }
    },
    //选择开始时间
    startChange(date, dateString) {
      this.beganTime = dateString;
      if (this.beganTime && this.endTime) {
        this.queryHand();
        this.getpatrolRecordManage();
      }
    },
    //选择结束时间
    endChange(date, dateString) {
      this.endTime = dateString;
      if (this.beganTime && this.endTime) {
        this.queryHand();
        this.getpatrolRecordManage();
      }
    },
    shanchuHand() {},
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 44px;
    left: 108px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .basic-info-con {
    position: absolute;
    top: 118px;
    left: 74px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .bas-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 112px;
        height: 16px;
        // margin-bottom: 23px;
      }
    }
    .bas-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      .time-con {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        ::v-deep .ant-input {
          background-color: #1a3566;
          border-radius: 4px;
          border: 1px solid #1e98f5;
          border-top-width: 1.02px;
          height: 43px;
          color: #00ccff;
        }

        .line {
          width: 5px;
          height: 1px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #29eaf3;
          margin-left: 11px;
          margin-right: 11px;
        }
        .shanchu {
          width: 32px;
          height: 32px;
          margin-left: 10px;
          margin-right: 17px;
        }
      }
      .option-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 18px;

        img {
          height: 11px;
          width: 11px;
          margin-right: 5px;
        }
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .chart-con {
    position: absolute;
    left: 50%;
    top: 138px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    align-items: center;
    margin-top: 38px;
    height: 710px;
    .chart-from-left {
      width: 487px;
      margin-right: 19px;
      background-color: rgba($color: #1a3566, $alpha: 0.23);
      border-radius: 20px;
      height: 100%;
      img {
        width: 100%;
        height: 46px;
      }
    }
    .chart-from-r {
      width: 931px;
      height: 100%;
      .top-con {
        width: 100%;
        height: 100%;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        img {
          width: 100%;
          height: 43px;
        }
      }
      .bottom-con {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .qydys-con {
          width: 560px;
          height: 340px;
          background-color: rgba($color: #1a3566, $alpha: 0.23);
          border-radius: 20px;
          img {
            width: 100%;
            height: 43px;
          }
        }
        .hgzdb-con {
          width: 351px;
          height: 340px;
          background-color: rgba($color: #1a3566, $alpha: 0.23);
          border-radius: 20px;
          margin-left: 20px;
          img {
            width: 100%;
            height: 43px;
          }
          .proportion {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            .jzt-con {
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              img {
                width: 180px;
                height: 215px;
              }
              span {
                margin-left: 27px;
              }
              .percentage {
                position: absolute;
                top: 39px;
                left: 46%;
                transform: translateX(-54%);
                h1 {
                  font-size: 18px;
                  font-family: DIN-Bold;
                  font-weight: 400;
                  color: #f7feff;
                  &:nth-child(1) {
                    font-size: 16px;
                    margin-bottom: 25px;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    margin-bottom: 26px;
                  }
                  &:nth-child(3) {
                    font-size: 14px;
                    margin-bottom: 30px;
                  }
                  &:nth-child(4) {
                    font-size: 12px;
                  }
                }
              }
            }
            .type-con {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .unit {
                font-size: 14px;
                font-weight: 400;
                color: #e5fdff;
              }
              .type-row {
                margin-top: 21px;
                margin-bottom: 4px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                span {
                  color: #f7feff;
                  font-size: 16px;

                  &:nth-child(1) {
                    margin-right: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .c-mian-con {
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      .title-con {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 14px;
        padding: 0 20px;
        span {
          color: #0bc8fd;
          width: 64px;
          &:nth-child(1) {
            margin-right: 91px;
          }
          &:nth-child(2) {
            margin-right: 250px;
          }
          &:nth-child(3) {
            margin-right: 207px;
          }
        }
      }
      ::v-deep .ant-progress-bg {
        height: 12px !important;
      }
      .swiper-container {
        position: relative;
        height: 100%;
        .swiper-slide {
          position: relative;
          width: 100%;
          &:nth-child(odd) {
            .info-row {
              background: #142951;
              border-radius: 5px;
            }
          }
        }
      }
      .list-con {
        margin-top: 20px;
        height: 550px;
        width: 100%;
        overflow-y: auto;
        .info-row {
          height: 55px;
          padding-left: 28px;
          display: flex;
          flex-direction: row;
          align-items: center;
          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f7fefe;
            &:nth-child(1) {
              width: 95px;
            }
            &:nth-child(2) {
              width: 230px;
              margin-right: 80px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-left: 53px;
            }
            &:nth-child(3) {
              width: 168px;
              margin-right: 85px;
            }
          }
          &:nth-child(odd) {
            background: #142951;
            border-radius: 5px;
          }
        }
      }
      .list-con::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .close {
    position: absolute;
    right: 74px;
    top: 99px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.available {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
</style>
