<template>
  <div class="overlay datav-box">
    <div class="mian-con">
      <div class="row-con" style="position: relative">
        <div class="data-screening-con">
          <div class="screening-title" :class="{ scrlshowin, scrlshowout }">
            <img :src="require('@/assets/images/btzl.png')" alt="" />
            <span style="font-family: 'ht'">数据总览</span>
          </div>
          <div class="scree-list">
            <div class="lc" :class="{ scrlshowin, scrlshowout }">
              <img
                class="sjbjk"
                :src="require('@/assets/images/sjbjk.png')"
                alt=""
              />
              <div @click="printChange" class="lc-con">
                <img :src="require('@/assets/images/he.png')" alt="" />
                <div class="lc-d">
                  <h1 class="he">
                    <countTo
                      :startVal="startVal"
                      :endVal="sjzlData.certificateCount"
                      :duration="duration"
                      style="font-family: 'bt'"
                    >
                    </countTo>
                  </h1>
                  <h1 v-show="printShow" style="font-family: 'ldt'">
                    合格证打印数
                  </h1>
                </div>
              </div>
            </div>
            <div class="lc" :class="{ scrlshowin: scrlshowins, scrlshowous }">
              <img
                class="sjbjk"
                :src="require('@/assets/images/sjbjk.png')"
                alt=""
              />
              <div @click="patrolChange" class="lc-con">
                <img :src="require('@/assets/images/xun.png')" alt="" />
                <div class="lc-d">
                  <h1 class="xun">
                    <countTo
                      :startVal="startVal"
                      :endVal="sjzlData.patrolCount"
                      :duration="duration"
                      style="font-family: 'bt'"
                    >
                    </countTo>
                  </h1>
                  <h1 v-show="patrolShow" style="font-family: 'ldt'">
                    巡查次数
                  </h1>
                </div>
              </div>
            </div>
            <div class="lc" :class="{ scrlshowin: scrlshowinr, scrlshowour }">
              <img
                class="sjbjk"
                :src="require('@/assets/images/sjbjk.png')"
                alt=""
              />
              <div @click="detectionChange" class="lc-con">
                <img :src="require('@/assets/images/jian.png')" alt="" />
                <div class="lc-d">
                  <h1 class="jian">
                    <countTo
                      :startVal="startVal"
                      :endVal="sjzlData.detectionCount"
                      :duration="duration"
                      style="font-family: 'bt'"
                    >
                    </countTo>
                  </h1>
                  <h1 v-show="detectionShow" style="font-family: 'ldt'">
                    检测数量
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          @click="switchChange"
          style="position: absolute; top: 129px; right: 45px; z-index: 99"
        >
          <img
            style="height: 40px; width: 40px"
            :src="require('@/assets/images/switch.png')"
            alt=""
          />
        </div>
        <div class="middlef-con">
          <div
            id="dtchart"
            class="dtchart"
            :style="{ opacity: mapShow ? '1' : '0' }"
          />
          <div
            class="mapInfo-con"
            :class="{ scrlshowin: scrlshowins, scrlshowous }"
          >
            <div v-show="videoShow" id="heatMap" class="heat-map"></div>
            <div v-show="!videoShow" class="heat-map">
              <video
                width="100%"
                height="100%"
                id="myVideo"
                :autoplay="true"
                :src="video"
                controls
                loop
                disablePictureInPicture
                muted
                style="
                  object-fit: fill;
                  border: none;
                  outline: none;
                  border-style: none;
                "
              />
            </div>
            <div class="info-bottom">
              <div class="top-title">
                <img :src="require(`@/assets/images/tut.png`)" />
                <span>{{ hgzShowInfo.name }}</span>
              </div>
              <div class="category">
                <img :src="require(`@/assets/images/xjxc.png`)" />
                <div class="main-in">
                  <div class="name-c">
                    <span style="font-family: 'ldt'">监督巡查</span>
                    <span style="font-family: 'ldt'"
                      >{{ hgzShowInfo.jiandu }}次</span
                    >
                  </div>
                  <div class="progress-con">
                    <div
                      v-for="(item, index) in progressBCount"
                      :key="`jianduprogressBCount-${index}`"
                      class="bottom-bar one-b"
                    >
                      <h1></h1>
                    </div>
                    <div class="top-bar">
                      <div
                        v-for="(item, idx) in progressCount"
                        :key="idx"
                        class="bottom-bar one-t"
                      >
                        <h1></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="category">
                <img :src="require(`@/assets/images/jyjc.png`)" />
                <div class="main-in">
                  <div class="name-c">
                    <span style="font-family: 'ldt'">检验检测</span>
                    <span style="font-family: 'ldt'"
                      >{{ hgzShowInfo.jiance }}次</span
                    >
                  </div>
                  <div class="progress-con">
                    <div
                      v-for="(item, index) in progressBCount"
                      :key="`jianceprogressBCount-${index}`"
                      class="bottom-bar two-b"
                    >
                      <h1></h1>
                    </div>
                    <div class="top-bar">
                      <div
                        v-for="(item, idx) in progressCount"
                        :key="idx"
                        class="bottom-bar two-t"
                      >
                        <h1></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="category">
                <img :src="require(`@/assets/images/hgzkj.png`)" />
                <div class="main-in">
                  <div class="name-c">
                    <span style="font-family: 'ldt'">合格证开具</span>
                    <span style="font-family: 'ldt'"
                      >{{ hgzShowInfo.kaiju }}张</span
                    >
                  </div>
                  <div class="progress-con">
                    <div
                      v-for="(item, index) in progressBCount"
                      :key="`kaijuprogressBCount-${index}`"
                      class="bottom-bar three-b"
                    >
                      <h1></h1>
                    </div>
                    <div class="top-bar">
                      <div
                        v-for="(item, idx) in progressCount"
                        :key="idx"
                        class="bottom-bar three-t"
                      >
                        <h1></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="mapbg6.png" id="mapbg" style="display: none" />
          <div class="jg-c">
            <div class="jgxx-con">
              <img :src="require(`@/assets/images/jgxx.png`)" alt="" />
              <span
                style="
                  font-family: 'ht';
                  font-weight: bold;
                  font-size: 16px;
                  color: #eff7ff;
                "
                >监管消息</span
              >
            </div>
            <img :src="require(`@/assets/images/jg.png`)" alt="" />
            <div
              v-if="messageList.length > 0"
              :class="{ scrlshowinm, scrlshowoutm }"
              class="news-list"
            >
              <swiper
                :options="recSwiperOptionjg"
                :not-next-tick="notNextTickOfRecSwiperjg"
                ref="recSwiperjg"
                class="swiper-item"
              >
                <swiper-slide
                  style="height: 0px !important"
                  v-for="(item, idx) in messageList"
                  :key="idx"
                >
                  <div class="list-con">
                    <div class="item-con">
                      <span
                        style="
                          font-family: 'digial';
                          font-weight: 400;
                          font-size: 12px;
                          color: #1978d0;
                        "
                        v-if="item.createTime"
                        >{{ item.createTime.substring(0, 10) }}</span
                      >
                      <span
                        style="
                          font-weight: 400;
                          font-size: 13px;
                          color: #ffffff;
                          font-family: 'ldt';
                        "
                        >{{ item.article }}</span
                      >
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <div class="left-middlef-bottom">
          <div style="margin-right: 22px">
            <div class="screening-title" :class="{ scrlshowin, scrlshowout }">
              <img
                style="width: 398px"
                :src="require('@/assets/images/btzl.png')"
                alt=""
              />
              <div class="hegez-title">
                <span style="font-family: 'ht'">合格证Top8</span>
                <div @click="printHand" class="hegez-ri">
                  <h1></h1>
                  <span>合格证打印数量</span>
                </div>
              </div>
            </div>
            <div class="econ-con">
              <div class="econ" id="bar"></div>
            </div>
          </div>
          <div>
            <div
              class="screening-title"
              :class="{ scrlshowin: scrlshowins, scrlshowous }"
            >
              <img
                style="width: 498px"
                :src="require('@/assets/images/btzl.png')"
                alt=""
              />
              <div class="hegez-title">
                <span style="font-family: 'ht'">日常巡查</span>
                <div @click="richaHand" class="hegez-ri">
                  <h1></h1>
                  <span>巡查详情</span>
                </div>
              </div>
            </div>
            <div class="gdhead-list">
              <div class="list-con">
                <div
                  class="item-con"
                  :class="{ scrlshowin: scrlshowins, scrlshowous }"
                >
                  <!-- <span>占位符</span> -->
                  <span style="margin-left: 30px">监管地区</span>
                  <span style="margin-left: 60px">巡查企业</span>
                  <span style="margin-left: 60px">巡查人员</span>
                  <span style="margin-left: 70px">时间</span>
                </div>
              </div>
            </div>
            <div class="news-list" v-if="patrolList.length > 0">
              <swiper
                :options="recSwiperOption"
                :not-next-tick="notNextTickOfRecSwiper"
                ref="recSwiper"
                class="swiper-item"
              >
                <swiper-slide v-for="(item, idx) in patrolList" :key="idx">
                  <div class="list-con">
                    <div class="item-con">
                      <div class="line"></div>
                      <div class="serial">
                        <span v-if="idx < 9">0{{ idx + 1 }}</span>
                        <span v-else>{{ idx + 1 }}</span>
                      </div>
                      <span class="regionName">{{ item.regionName }}</span>
                      <span class="subjectName">{{ item.subjectName }}</span>
                      <span class="createUserName">{{
                        item.createUserName
                      }}</span>
                      <span class="time" v-if="item.createTime">{{
                        item.createTime.substring(0, 10)
                      }}</span>
                      <div class="line"></div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="row-con">
        <div class="module-one">
          <div class="row-con-tl">
            <div
              class="screening-title"
              :class="{ scrlshowinri, scrlshowoutri }"
            >
              <img :src="require('@/assets/images/btzl.png')" alt="" />
              <span style="font-family: 'ht'">主体基本信息</span>
            </div>
            <div class="qiye-mod" :class="{ scrlriSshowin, scrlriSshowout }">
              <img
                class="dizuo"
                :src="require('@/assets/images/xxdb.png')"
                alt=""
              />
              <div class="qiye-mod-icon">
                <div class="mod-data-l">
                  <div class="info-con">
                    <img
                      class="dip"
                      :src="require('@/assets/images/dizuo.png')"
                      alt=""
                    />
                    <div class="tolal-data">
                      <span class="qiye">
                        <countTo
                          :startVal="startVal"
                          :endVal="leftOne.totalSubjectNum"
                          :duration="duration"
                          style="font-family: 'bt'"
                        ></countTo>
                        <span style="margin-left: -10px; font-family: 'ldt'"
                          >家</span
                        ></span
                      >
                      <!-- <h1 style="font-family: 'ldt'">入网主体</h1> -->
                      <img :src="require('@/assets/images/qy.png')" alt="" />
                    </div>
                    <div @click="mainDataHand" class="see-con">
                      <h1></h1>
                      <span>入网主体</span>
                    </div>
                  </div>
                  <!-- <div class="info-con">
                    <img
                      class="dip"
                      :src="require('@/assets/images/dizuo.png')"
                      alt=""
                    />
                    <div class="tolal-data">
                      <span class="cluo">
                        <countTo
                          :startVal="startVal"
                          :endVal="leftOne.totalSubjectNum"
                          :duration="duration"
                        ></countTo>
                        <span style="margin-left: -10px">家</span>
                      </span>
                      <h1>企业评级</h1>
                      <img :src="require('@/assets/images/pj.png')" alt="" />
                    </div>
                    <div @click="qypfHand" class="see-con">
                      <span>查看</span>
                      <a-icon type="right" />
                    </div>
                  </div> -->
                  <div class="info-con">
                    <img
                      class="dip"
                      :src="require('@/assets/images/dizuo.png')"
                      alt=""
                    />
                    <div class="tolal-data">
                      <span class="lp">
                        <countTo
                          :startVal="startVal"
                          :endVal="leftOne.certSubjectNum"
                          :duration="duration"
                          style="font-family: 'bt'"
                        >
                        </countTo>
                        <span style="margin-left: -10px; font-family: 'ldt'"
                          >家</span
                        >
                      </span>
                      <!-- <h1 style="font-family: 'ldt'">两品一标</h1> -->
                      <img :src="require('@/assets/images/lpyb.png')" alt="" />
                    </div>
                    <div @click="selectSignHand" class="see-con">
                      <h1></h1>
                      <span>两品一标</span>
                    </div>
                  </div>

                  <!-- <div class="info-con">
                  <img
                    class="dip"
                    :src="require('@/assets/images/dizuo.png')"
                    alt=""
                  />
                  <div class="tolal-data">
                    <span class="scp">
                      <countTo
                        :startVal="startVal"
                        :endVal="leftOne.batchNum"
                        :duration="duration"
                      ></countTo>
                      <span style="margin-left: -10px">次</span></span
                    >
                    <h1>生产批次</h1>
                    <img :src="require('@/assets/images/scpc.png')" alt="" />
                  </div>
                </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row-con-tr">
            <div
              class="screening-title"
              :class="{ scrlshowinrit, scrlshowoutrit }"
            >
              <img :src="require('@/assets/images/btzl.png')" alt="" />
              <div class="hegez-title">
                <span style="font-family: 'ht'">企业评分</span>
                <div @click="qypfHand" class="hegez-ri">
                  <h1></h1>
                  <span>评分详情</span>
                </div>
              </div>
            </div>
            <div
              class="qiyphz-con"
              :class="{ scrlrisoshowin, scrlrisoshowout }"
            >
              <div class="item-row">
                <img :src="require('@/assets/images/luma.png')" alt="" />
                <h1 style="font-family: 'bt'">{{ greenCodeList.length }}</h1>
                <h1 class="m">绿码企业</h1>
              </div>
              <div class="item-row">
                <img :src="require('@/assets/images/hangm.png')" alt="" />
                <h1 style="font-family: 'bt'">{{ yellowCodeList.length }}</h1>
                <h1 class="m">黄码企业</h1>
              </div>
              <div class="item-row">
                <img :src="require('@/assets/images/hongm.png')" alt="" />
                <h1 style="font-family: 'bt'">{{ redCodeList.length }}</h1>
                <h1 class="m">红码企业</h1>
              </div>
            </div>
          </div>
          <!-- <div class="row-con-tr">
            <div class="screening-title">
              <img :src="require('@/assets/images/btzl.png')" alt="" />
              <div class="hegez-title">
                <span>豇豆专项检测</span>
                <div @click="jiangdouHand" class="hegez-ri">
                  <h1></h1>
                  <span>检测详情</span>
                </div>
              </div>
            </div>
            <div class="qiyphz-con">
              <div class="item-row">
                <div class="zxjc-icon">
                  <img :src="require('@/assets/images/jgicon.png')" alt="" />
                  <img :src="require('@/assets/images/jgcj.png')" alt="" />
                </div>
                <h1>{{ jgcjSl }}</h1>
                <h1>基地抽检检测数量</h1>
              </div>
              <div class="item-row">
                <div class="zxjc-icon">
                  <img :src="require('@/assets/images/ljicon.png')" alt="" />
                  <img :src="require('@/assets/images/lsj.png')" alt="" />
                </div>
                <h1>{{ lscjSl }}</h1>
                <h1>市场抽检检测数量</h1>
              </div>
              <div class="item-row">
                <div class="zxjc-icon">
                  <img :src="require('@/assets/images/jtjicon.png')" alt="" />
                  <img :src="require('@/assets/images/jtj.png')" alt="" />
                </div>
                <h1>{{ jtjjcSl }}</h1>
                <h1>胶体金检测数量</h1>
              </div>
            </div>
          </div> -->
        </div>
        <div class="module-two">
          <div class="two-left">
            <div
              class="screening-title"
              :class="{ scrlshowinri, scrlshowoutri }"
            >
              <img :src="require('@/assets/images/btzl.png')" alt="" />
              <div class="hegez-title">
                <span style="font-family: 'ht'">质量安全检测</span>
                <div @click="quickHand" class="hegez-ri">
                  <h1></h1>
                  <span>检测汇总</span>
                </div>
              </div>
            </div>
            <div class="kuaisjc-con" :class="{ scrlriSshowin, scrlriSshowout }">
              <div style="z-index: 0" class="kuaisjc-l">
                <div class="pie-con" id="pie"></div>
              </div>
              <div class="kuaisjc-r">
                <img :src="require('@/assets/images/lottot.png')" alt="" />
                <div class="samples">
                  <h1>
                    <countTo
                      :startVal="startVal"
                      :endVal="leftTwototal"
                      :duration="duration"
                      style="font-family: 'bt'"
                    ></countTo>
                  </h1>
                  <h1 style="font-family: 'ldt'">样本检测 总数</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="two-ri">
            <div
              class="screening-title"
              :class="{ scrlshowinrit, scrlshowoutrit }"
            >
              <img :src="require('@/assets/images/btzl.png')" alt="" />
              <div class="hegez-title">
                <span style="font-family: 'ht'">豇豆专项检测</span>
                <div @click="jiangdouHand" class="hegez-ri">
                  <h1></h1>
                  <span>检测详情</span>
                </div>
              </div>
            </div>
            <div
              class="qiyphz-con"
              :class="{ scrlrisoshowin, scrlrisoshowout }"
            >
              <div class="item-row">
                <div class="zxjc-icon">
                  <a-progress
                    type="dashboard"
                    :strokeWidth="9"
                    :percent="percentCheckSum"
                    :width="110"
                    :stroke-color="{
                      '0%': '#06D6FC',
                      '100%': '#1273F8',
                    }"
                  />
                  <!-- <img :src="require('@/assets/images/jgicon.png')" alt="" /> -->
                  <img :src="require('@/assets/images/lsj.png')" alt="" />
                  <div class="name">
                    <h1>基地抽检</h1>
                  </div>
                </div>
                <h1 style="font-family: 'bt'; margin-top: 10px">
                  {{ jgcjSl }}
                </h1>
              </div>
              <div class="item-row">
                <div class="zxjc-icon">
                  <a-progress
                    :stroke-color="{
                      '0%': '#06D6FC',
                      '100%': '#1273F8',
                    }"
                    :strokeWidth="9"
                    type="dashboard"
                    :percent="percentTemporSum"
                    :width="110"
                  />
                  <!-- <img :src="require('@/assets/images/ljicon.png')" alt="" /> -->
                  <img :src="require('@/assets/images/jgcj.png')" alt="" />
                  <div class="name">
                    <h1>市场抽检</h1>
                  </div>
                </div>
                <h1 style="font-family: 'bt'; margin-top: 10px">
                  {{ lscjSl }}
                </h1>
              </div>
              <div class="item-row">
                <div class="zxjc-icon">
                  <a-progress
                    :stroke-color="{
                      '0%': '#06D6FC',
                      '100%': '#1273F8',
                    }"
                    :strokeWidth="9"
                    type="dashboard"
                    :percent="percentCardSum"
                    :width="110"
                  />
                  <!-- <img :src="require('@/assets/images/jtjicon.png')" alt="" /> -->
                  <img :src="require('@/assets/images/jtj.png')" alt="" />
                  <div class="name">
                    <h1>胶体金</h1>
                  </div>
                </div>
                <h1 style="font-family: 'bt'; margin-top: 10px">
                  {{ jtjjcSl }}
                </h1>
              </div>
            </div>
            <!-- <div v-if="messageList.length > 0" class="news-list">
              <swiper
                :options="recSwiperOptionjg"
                :not-next-tick="notNextTickOfRecSwiperjg"
                ref="recSwiperjg"
                class="swiper-item"
              >
                <swiper-slide
                  style="height: 0px !important"
                  v-for="(item, idx) in messageList"
                  :key="idx"
                >
                  <div class="list-con">
                    <div class="item-con">
                      <span v-if="item.createTime">{{
                        item.createTime.substring(0, 10)
                      }}</span>
                      <span>{{ item.accessSubjectName }}</span>
                      <span>{{ item.article }}</span>
                      <h1 v-if="item.messageType == 2" class="badge"></h1>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div> -->
          </div>
        </div>
        <div class="module-three">
          <div class="screening-title" :class="{ scrlshowinri, scrlshowoutri }">
            <img :src="require('@/assets/images/btzl.png')" alt="" />
            <div class="hegez-title">
              <span style="font-family: 'ht'">监管队伍</span>
              <div @click="ncqwghHand" class="hegez-ri">
                <h1></h1>
                <span>队伍详情</span>
              </div>
            </div>
          </div>
          <div class="ncp-con">
            <div class="list-con" :class="{ scrlrisoshowin, scrlrisoshowout }">
              <img :src="require('@/assets/images/jgdw.png')" alt="" />
              <div class="ncp-in">
                <countTo
                  :startVal="startVal"
                  :endVal="rightTwo.total"
                  :duration="duration"
                  style="font-family: 'bt'"
                ></countTo>
                <p style="font-family: 'ldt'">监管队伍总数</p>
              </div>
            </div>
            <div class="list-con" :class="{ scrlshowinrit, scrlshowoutrit }">
              <img :src="require('@/assets/images/jgy.png')" alt="" />
              <div class="ncp-in">
                <countTo
                  :startVal="startVal"
                  :endVal="rightTwo.totalSupervisor"
                  :duration="duration"
                  style="font-family: 'bt'"
                ></countTo>
                <p style="font-family: 'ldt'">监管员数量</p>
              </div>
            </div>
            <div class="list-con" :class="{ scrlshowinri, scrlshowoutri }">
              <img :src="require('@/assets/images/xgy.png')" alt="" />
              <div class="ncp-in">
                <countTo
                  :startVal="startVal"
                  :endVal="rightTwo.totalAssistant"
                  :duration="duration"
                  style="font-family: 'bt'"
                ></countTo>
                <p style="font-family: 'ldt'">协管员数量</p>
              </div>
            </div>
          </div>
        </div>
        <div class="module-four">
          <div
            class="screening-title"
            :class="{ scrlshowinrit, scrlshowoutrit }"
          >
            <img :src="require('@/assets/images/btzl.png')" alt="" />
            <span style="font-family: 'ht'">合格证每日打印数量</span>
            <div v-if="month" class="ri">
              <a-month-picker
                placeholder="选择日期"
                :default-value="month"
                @change="timeOnChange"
                :allowClear="false"
              >
                <a-icon
                  slot="require('@/assets/images/rl.png')"
                  type="default"
                />
              </a-month-picker>
            </div>
          </div>
          <div class="econ-con">
            <div style="z-index: 0" class="econ" id="line"></div>
          </div>
        </div>
      </div>
    </div>

    <!--  两品一标 -->
    <div v-if="lpPopShow" class="modal-con-lpyb">
      <lpyb-data
        @close="handlealpCancel"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
      />
    </div>
    <!-- 合格证打印数量 -->
    <div v-if="printPopShow" class="modal-con">
      <print-data
        :key="key"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        @close="handleaprintCancel"
        :type="1"
      />
    </div>
    <!-- 质量安全检测 -->
    <div v-if="jiancPopShow" class="modal-con">
      <quick-data
        :key="key"
        :productName="productName"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        :productNameList="productNameList"
        @close="handlejiancCancel"
        ref="mesData"
      />
    </div>
    <!-- 日常巡查 -->
    <div v-if="ricPopShow" class="modal-con">
      <daily-data
        :key="key"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        @close="handlerichaCancel"
      />
    </div>
    <!-- 农产品质量安全监管 -->
    <div v-if="anqjgPopShow" class="modal-con-an">
      <wgh-data
        :key="key"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        :regionName="regionName"
        :region="region"
        @close="handleaqjcCancel"
      />
    </div>
    <!-- 入网主体 -->
    <div v-if="qiyePopShow" class="modal-con">
      <subject-data
        :key="key"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        :regionName="regionName"
        :region="region"
        @close="handleqiyeCancel"
      />
    </div>
    <div v-if="scorePopShow" class="modal-con">
      <qyscore
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        :region="region"
        :key="key"
        @close="handleScoreCancel"
      />
    </div>
    <!-- 豇豆专项检测 -->
    <div v-if="jiangdouPopShow" class="modal-con-jd">
      <special-jc
        :key="key"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        :regionName="regionName"
        :region="region"
        @close="handleJiangdouCancel"
      />
    </div>
  </div>
</template>

<script>
import Index from "./index";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.dvs-con {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.dvsma-facestcon {
  @include flrowjusali();
}
.scrlshowin {
  opacity: 0;
  transform: translateX(-80px);
}
.scrlshowout {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlshowous {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlshowour {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlshowinm {
  opacity: 0;
  transform: translateX(-80px);
}
.scrlshowoutm {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlshowinri {
  opacity: 0;
  transform: translateX(100px);
}
.scrlshowoutri {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlshowinrit {
  opacity: 0;
  transform: translateX(100px);
}
.scrlshowoutrit {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlriSshowin {
  opacity: 0;
  transform: translateX(100px);
}
.scrlriSshowout {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
.scrlrisoshowin {
  opacity: 0;
  transform: translateX(100px);
}
.scrlrisoshowout {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-out;
}
</style>
